import config from '../config.json'

const konsole = {
    log:(...args)=>{
        if (config.DEMO) {
            console.log(...args);
        }
    },
    error:(...args)=>{
        if (config.DEMO) {
            console.error(...args);
        }
    },
    decor:(...args)=>{
        if (config.DEMO) {
            console.log('-*-*-*-',...args,'-*-*-*-');
        }
    },
    encapse:(...args)=>{
        if (config.DEMO) {
            console.log('-*-*-*-*-*-*--*-*-*-*-*-*--*-*-*-*-*-*-');
            console.log(...args);
            console.log('-*-*-*-*-*-*--*-*-*-*-*-*--*-*-*-*-*-*-');
        }
    }
}

export default konsole;