import React, { useState } from 'react'
import { Modal, Button } from "react-bootstrap";
import "./Toolboxtiles.css"
const ToolBoxTiles = ({ show, handleClose }) => {
  const [showFinanceTile, setshowFinanceTile] = useState(false);
  const [showHousingTile, setshowHousingTile] = useState(false);
  const [showEstatePlanning, setShowEstatePlanning] = useState(false);
  const tilesArr = [
    {
      imageSrc: "healthTile.png",
      title: "Health ToolBox",
      subtitle: "CREATE",
      isCommingSoon: true,
    },
    {
      imageSrc: "legal.svg",
      title: "Legal ToolBox",
      subtitle: "CREATE",
      isCommingSoon: false,
    },
    {
      imageSrc: "FinanceTile.png",
      title: "Finance ToolBox",
      subtitle: "CREATE",
      isCommingSoon: false,
    },
    {
      imageSrc: "OtherTile.png",
      title: "Other",
      subtitle: "CREATE",
      isCommingSoon: true,
    },
  ];

  const financeArrTile = [
    {
      imageSrc: "TaxTile.png",
      title: "Tax",
      subtitle: "CREATE",
      isCommingSoon: true,
    },
  ];

  const housingArrTile = [
    {
      imageSrc: "EstateTile.png",
      title: "Estate Planning",
      subtitle: "CREATE",
      isCommingSoon: false,
    },
  ];

  const handleClosefun = () => {
    if (showFinanceTile) {
      setshowFinanceTile(false);
    } else if (showHousingTile) {
      setshowHousingTile(false);
    } else {
      handleClose();
    }
  };

  const handleTiles = (title) => {
    if (title === "Finance ToolBox") {
      setshowFinanceTile(!showFinanceTile);
    } else if (title === "Legal ToolBox") {
      setshowHousingTile(!showHousingTile);
    } else if (title === "Estate Planning") {
      setShowEstatePlanning(true);
    }
  };
  

  const returTile = (item, index) => {
    console.log(item, index, "itititiitititititi");
    let pointer = item?.isCommingSoon == true ? "none" : "cursor";
    return (
      <div
        key={index}
        className="card shadow-lg p-0 toolbox_tiles_main-card"
        style={{ backgroundColor: "#CD9A27", pointerEvents: pointer }}
        onClick={() => handleTiles(item.title)}
      >
        <a style={{ textDecoration: "none" }}>
          <div className="card-body toolbox_tiles_inner-CardBody justify-content-center">
            <div className="d-flex justify-content-center">
              <img src={item.imageSrc} className="img-fluid cardimg" />
            </div>
            <h5 className="toolbox_tiles_card-title text-center mt-3">
              {item.title}
            </h5>
          </div>
          {item.isCommingSoon && (
            <div className="coming-soon1 mt-2">
              <span>COMING SOON</span>
            </div>
          )}
          <div className="text-center pt-2 bottom-card-tittle">
            {item.subtitle}
          </div>
        </a>
      </div>
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClosefun()}
        size={`${
          showFinanceTile == true || showHousingTile == true ? "md" : "xl"
        }`}
        centered
        backdrop={false}
        style={{ background: "rgba(0,0,0,0.6)" }}
      >
        <div className="background-Image-Div-ToolBox">
        <Modal.Header style={{border:'none'}} closeButton> </Modal.Header>
        <Modal.Body style={{padding:'0'}}>
          <>
            <div className="col d-flex justify-content-evenly align-items-start flex-wrap">
                {showFinanceTile == true ? (
                  <>
                    {financeArrTile?.length > 0 &&
                      financeArrTile?.map((item, index) => {
                          return (<div key={'d' + index}>
                            { returTile(item, index)}
                          </div>);
                      })}
                    </>
                ) : showHousingTile == true ? (
                  <>
                    {housingArrTile?.length > 0 &&
                      housingArrTile?.map((item, index) => {
                          return (<div key={'f' + index}>
                            { returTile(item, index)}
                          </div>);
                      })}
                  </>
                ) : (
                  <>
                    {tilesArr?.length > 0 &&
                      tilesArr?.map((item, index) => {
                        return (<div key={'e' + index}>
                          { returTile(item, index)}
                        </div>);
                      })}
                  </>
                )}
            </div>
            {/* <FinanceTiles setshowFinanceTile={setshowFinanceTile}  showFinanceTile={showFinanceTile}  /> */}

            <EstatePlanningModal setShowEstatePlanning={setShowEstatePlanning} showEstatePlanning={showEstatePlanning} />

          </>
        </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

const EstatePlanningModal = ({ showEstatePlanning, setShowEstatePlanning }) => {
  const continueToRegister = () => {
    
    window.open(
      "http://register.virtualestateplanningsystem.com/?t=2d32f28b-38a8-443b-9203-4a5e0b3949c9",
      "_blank"
    );
};
  return (
    <>
      <Modal
        show={showEstatePlanning}
        onHide={() => setShowEstatePlanning(false)}
        size="lg"
        centered
        backdrop={false}
        style={{ background: "rgba(0,0,0,0.6)" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="modalTitleText">YOU ARE LEAVING THE SITE</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBodyText">
          If you click the “Continue” button, you will leave the AgingOptions
          website. This is a link to a third-party site. We do not control
          third-party sites in any way, and you acknowledge and agree that we
          are not responsible or liable for the content, availability,
          functions, accuracy, legality, appropriateness, advertising,
          products, information, use of user information, security or privacy
          policies and practices, or any other aspect or materials of any
          third-party site. Please be aware that the security and privacy
          policies on third-party sites may be different than AgingOptions
          policies, so please read third-party privacy and security policies
          closely. In no event shall we be liable, directly or indirectly, to
          anyone for any damage or loss caused or alleged to be caused by or
          in connection with the use of or reliance on any content, goods or
          services available on or through any third-party Site.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => continueToRegister()}>
            {" "}
            Continue
          </Button>
          <Button
            variant="primary"
            onClick={() => setShowEstatePlanning(false)}
          >
            {" "}
            Go Back
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default ToolBoxTiles;