// import moment from "moment";

const commonLib = {
    getQueryParameters: function (urlQuery, name) {
        return new URLSearchParams(urlQuery).get(name);
    },
    setSessionStoarge: function (key, value) {
        sessionStorage.setItem(key, value);
    },
    getSessionStoarge: function (value) {
        return sessionStorage.getItem(value);
    },
    getObjFromStorage: function (key) {
        return JSON.parse(sessionStorage.getItem(key));
    },
    // getFormattedTime: function (seminarTime) {
    //     return moment(seminarTime, "HH:mm:SS").format("hh:mm A");
    // },
    // getFormattedDate: function (seminarDate) {
    //     return moment(seminarDate).format(' Do MMMM YYYY')
    // },
    getVenueObj: function (json) {
        let params = {};
        params['venueAddressId'] = json.venueAddressId;
        params['venueAddressLine1'] = json.venueAddressLine1;
        params['venueAddressLine2'] = json.venueAddressLine2;
        params['venueAddressLine3'] = json.venueAddressLine3;
        params['venueCity'] = json.venueCity;
        params['venueCountry'] = json.venueCountry;
        params['venueCounty'] = json.venueCounty;
        params['venueId'] = json.venueId;
        params['venueState'] = json.venueState;
        params['venueText'] = json.venueText;
        params['venueZipCode'] = json.venueZipCode;
        return params
    },
    getSeminarObj: function (json) {
        let params = {};
        params['seminarDate'] = json.seminarDate;
        params['seminarTime'] = json.seminarTime;
        params['seminarId'] = json.seminarId;
        params['subtenantId'] = json.subtenantId;
        params['seminarTopic'] = json.seminarTopic;
        return params;
    },
    getObjFromStorage: function (key) {
        return JSON.parse(sessionStorage.getItem(key));
    },
    GetQueryValues: (query,param)=> {
        var url = "";
        url = query.slice(query.indexOf('?') + 1).split('&');
        // console.log(url);
        for (var i = 0; i < url.length; i++) {
          var urlparam = url[i].split('=');
          if (urlparam[0] == param) {
            return urlparam[1].split("#")[0];
          }
        }
        return "";
      },
      reversePassword:(str)=>{
        const reversedStr = str.split('').reverse().join('');
        let result = '';
      
        for (let i = 0; i < reversedStr.length; i++) {
          let charCode = reversedStr.charCodeAt(i);
      
          if (charCode === 65 || charCode === 97) {
            charCode = charCode + 25;
          } else if (
            (charCode >= 66 && charCode <= 90) ||
            (charCode >= 98 && charCode <= 122)
          ) {
            charCode = charCode - 1;
          }
      
          result += String.fromCharCode(charCode);
        }
      
        return result;
    
  }
}

export default commonLib;