import React, { useState, createContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Betahome from './component/Betahome';
import Login from './pages/login'
import LoaderScr from './component/LoaderScr';
import ExtendValidity from './component/ExtendValidity';
import CustomeIdleTimer from './component/CustomeIdleTimer';
export const UserContext = React.createContext({
  disable: false,
  loader: false,
});

function App() {
  const [isLoading, setIsLoading] = useState(false)
  return (
    <UserContext.Provider value={{ setIsLoading: setIsLoading }}>


      <div className="App">
        <LoaderScr isLoading={isLoading} />
        <CustomeIdleTimer />

        <BrowserRouter>
          <Routes>
            <Route path="/login" exact element={<Login />} />
            <Route path="/index" exact element={<Betahome />} />
            <Route path="/validity" exact element={<ExtendValidity/>} />
          </Routes>
        </BrowserRouter>

      </div>
    </UserContext.Provider>
  );
}

export default App;
