import React, { useState ,useEffect} from "react";
import "./styles/NavBar.css"
import Sidemenu from "./styles/Sidemenu.css";
import commonLib from "../src/control/commonLib";
import konsole from "../src/network/konsole";
import Services from "../src/network/Services"
import { AoLoginBaseUrl } from "./network/url";
import { Button, Modal } from 'react-bootstrap';

const Navbar = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const loggedUserDetail = commonLib.getObjFromStorage('userLoggedInDetail');
  const userdata = commonLib.getObjFromStorage('userdata');
  const [showModal, setshowModal] = useState(false)
  
  const [subtenantImage, setsubtenantImage] = useState("");
  const [roleIdValue, setRoleIdValue] = useState()


  const handleModalsubmit = () => {
    setshowModal(false);
    const stateObj = commonLib.getObjFromStorage('stateObj');
    let params = `appState=${stateObj?.appState}&userId=${stateObj?.userId}&roleId=${stateObj?.roleId}&loggenInId=${stateObj?.loggenInId}&success=${true}`;
   sessionStorage.clear();
   window.location.replace(`${AoLoginBaseUrl}Account/Signout?token=` +window.btoa(params));
  }
  const handleModalClose = () => {
    setshowModal(false);
    };
  const handleClick =()=>{
    setshowModal(true);
  }  




  useEffect(() => {
    const subtenantId = commonLib.getObjFromStorage("subtenantId");
    let valueRoleId = commonLib.getObjFromStorage("stateObj")?.roleId
    setRoleIdValue(valueRoleId)
    Services.getSubtenantDetail(subtenantId, true)
      .then((res) => {
        setsubtenantImage(res.data?.data[0]?.subtenantLogoUrl);
      })
      .catch((err) => {
        konsole.log("ErrorData", err);
      });
  }, []);


  konsole.log("subtenantImage",subtenantImage)

  return (
    <>
    <div className="header d-flex justify-content-between align-items-center w-100  border-0 border-bottom">
  {/*  */}
      <div class="">
        {/* <img src='/logoImage.svg' className='logo' /> */}
        <img src={subtenantImage} className='logo' />
      </div>
      <div className="d-flex align-items-center ">
          {/* <Image src='/images/Vector (5).png' className='bell d-none d-md-block d-lg-block'/> */}
        <p className='text d-none d-md-block d-lg-block m-0 '>Hi,{" "}
         {userdata?.loginUserName}
        </p>
          <img className='admin ms-2'  onClick={handleClick} style={{cursor: 'pointer'}} src='icons/logoutcircle.svg' width={25}/>
        </div>
    </div>
    <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header 
        closeButton>
          <span className="">Are you sure you want to logout ?</span>
        
          {/* <Modal.Title>Are you sure you want to logout?</Modal.Title> */}
        </Modal.Header>
        {/* <Modal.Body>{"Are you sure you want to logout?"}</Modal.Body> */}
        <Modal.Footer className="modal_s">
          <Button variant="secondary" onClick={handleModalClose} style = {{ backgroundColor: 'white', color: '#720c20' }} >
            No
          </Button>
          <Button variant="primary" onClick={handleModalsubmit} style={{ backgroundColor: '#720c20', color: 'white',border:"none" }}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
    
  );
};


export default Navbar