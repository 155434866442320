import auth0 from 'auth0-js'
// import auth0Cred from '../pages/auth0Cred.json';
import {lagecyauth0Cred } from './auth0Cred';

function auth0Config_Legacy() {

    let auth0Config_Legacy = new auth0.WebAuth({
        domain:lagecyauth0Cred?.domain,
        clientID:lagecyauth0Cred?.clientID,
        responseType:lagecyauth0Cred?.responseType,
        redirectUri:lagecyauth0Cred?.redirectUri,
        scope:lagecyauth0Cred?.scope
    })

    return auth0Config_Legacy;
}

export { auth0Config_Legacy}
