
import React, { useState, useRef, useEffect,useContext} from 'react';
import Services from '../network/Services';
import {AoPaymentUrl,AoPartnerBaseUrl} from '../network/url';
import { UserContext } from "../App";


const Payment = (props) => {
  const clickbuttonref = useRef()
  const loggedUserId=JSON.parse(sessionStorage.getItem('userdata'))
  const loggedId=JSON.parse(sessionStorage.getItem('stateObj'))
  const [orderId, setorderId] = useState('')
  const { setIsLoading } = useContext(UserContext);
  
  useEffect(() => {
    // adduserApiCall()
  }, [props?.showPayment])


  useEffect(() => {
    if (orderId !== '') {
      clickbuttonref.current.submit()
    }
  }, [orderId])

  const adduserApiCall = (data) => {
   
    let jsonObj = {
      "userId": loggedUserId?.memberUserId,
      'createdBy': loggedUserId?.memberUserId,
      "shippingCost": 0,
      "taxCost": 0,
      "totalCost": "300",
      "productList": [{
        "productType": "Ao-Academy",
        "productId": loggedId?.loggenInId,
        "productName": "Ao-Academy",
        "quantity": 1,
        "productPrice": "300",
      }]
    }
    setIsLoading(true)
    Services.postuserorderAddUserOrder(jsonObj)
    .then((response) => {
        if (response) {
          setIsLoading(false)
            console.log("postMemberAddress",response.data?.data?.order?.orderId)
            setorderId(response.data?.data?.order?.orderId)
          }
    })
    .catch((err) => {
    });
   
  }



  return (
    <>
      <form action={`${AoPaymentUrl}Catalog/PaymentRequest`} method="POST" ref={clickbuttonref} >
        <div>
          <input type="hidden" name="AppState" id="AppState" value={loggedUserId?.appState} />
          <input type="hidden" name="ReturnUrl" id="ReturnUrl" value={`${AoPartnerBaseUrl}validity`}/>

          <input type="hidden" name="UserId" value={loggedUserId?.memberUserId} />
          <input type="hidden" name="OrderId" value={orderId} />
          <input type="hidden" name="PaymentType" value={"Card"}/>
        </div>
        <div>
        </div>
      </form>


    </>
  )
}
export default Payment;