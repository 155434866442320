import React, { useState,useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import commonLib from "../control/commonLib";
import konsole from "../network/konsole";
import { AoLoginBaseUrl } from "../network/url";
import Services from "../network/Services";



const LoginPage = (props) => {  
  const navigate = useNavigate();
  const location = useLocation();
  const tokenKey = location.search;


  // konsole.log("aa", props)
  useEffect(() => { 
    const tokenMapKey = commonLib.GetQueryValues(tokenKey, "token");
    konsole.log("tokenMapKey", tokenMapKey);
    const decodedKey = window.atob(tokenMapKey);
    konsole.log("dece", decodedKey);
    const loggenInId = commonLib.GetQueryValues(decodedKey, "loggenInId") || "";
    const userId = commonLib.GetQueryValues(decodedKey, "userId") || "";
    const roleId = commonLib.GetQueryValues(decodedKey, "roleId") || "";
    const appState = commonLib.GetQueryValues(decodedKey, "appState") || "";
    const Pswd = commonLib.GetQueryValues(decodedKey, "Psw") || "";
    konsole.log("roleIdroleId",Pswd,JSON.parse(roleId))
    konsole.log("Pswd",Pswd,JSON.parse(roleId))


    if (loggenInId !== '' && userId !== '' && roleId !== '' && appState !== '') {
      getLoggedInUser(userId, appState, loggenInId, roleId,Pswd);
      
    }
    else {
      window.location.replace(`${AoLoginBaseUrl}Account/Signin`);
    }
  }, [tokenKey])


  const getLoggedInUser = (userId, appState, loggenInId, roleId,Pswd) => {
    konsole.log("roleIdroleId",roleId)
    const userRoleName = JSON.parse(roleId).filter(data => (data?.roleId==20  || data?.roleId == 9 || data?.roleId == 17 || data?.roleId == 5 || data?.roleId == 18))[0]?.roleId;

    let promises = Services.getLoggedInUser(userId, appState, loggenInId, userRoleName)
    promises.then(response => {

      // konsole.log("get", response)
      const userdata = response?.data?.data
    commonLib.setSessionStoarge("userdata", JSON.stringify(userdata));
    sessionStorage.setItem("subtenantId",JSON.stringify(userdata?.subtenant_Id))

    const stateObj = {
      loggenInId: loggenInId,
      userId: userId,
      roleId: roleId,
      appState: appState
    }
    commonLib.setSessionStoarge("stateObj", JSON.stringify(stateObj));
    // navigate("/index", { replace: true });
    navigate("/index",{ state: { pswd: Pswd } });

    }).catch(err => {
      konsole.log("err", err);
    })
      .finally(() => {
        konsole.log("finish");
      })

    
  }

  return (
    <div>
      {
        <>
        </>
      }
    </div>
  )
};


export default LoginPage;
